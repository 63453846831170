
import React from 'react';

const About = () => {
  return (
    <div className='bg-gradient-to-b from-gray-800 to-blue-950'>
    <div className="max-w-3xl mx-auto p-6 text-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-4">About Our Ethereum Wallet dApp</h1>
      
      <p className="text-lg mb-6">
        Welcome to our decentralized application (dApp), a secure and user-friendly Ethereum wallet solution. 
        With this dApp, users can create, load, and manage Ethereum wallets, transfer Ethereum, view transaction history, and much more. 
        Our goal is to provide you with a seamless blockchain experience.
      </p>

      <h3 className="text-xl font-semibold mb-4">Core Features:</h3>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Create Wallet:</strong> Easily generate a new Ethereum wallet secured by a 12-word recovery phrase.</li>
        <li><strong>Load Wallet:</strong> Access your existing wallet by securely entering your secret recovery phrase.</li>
        <li><strong>Transfer Ethereum:</strong> Transfer ETH or other tokens with complete control over gas fees and transaction details.</li>
        <li><strong>View Transaction History:</strong> Keep track of all your past transactions right from your wallet.</li>
        <li><strong>Multi-Account Support:</strong> Add multiple accounts to the wallet and seamlessly switch between them as needed.</li>
        <li><strong>Network Switching:</strong> Easily switch between blockchain networks such as Ethereum, Polygon, Linea, and more.</li>
      </ul>

      <h3 className="text-xl font-semibold mb-4">Upcoming Features:</h3>
      <ul className="list-disc list-inside mb-6">
        <li><strong>NFT Integration:</strong> Soon, you will be able to manage your NFTs (Non-Fungible Tokens) directly in the wallet, allowing you to view, transfer, and trade NFTs with ease.</li>
        <li><strong>Wallet Native Currency:</strong> Wallet native currency/token will be used to incentivize the users based on their transactions and activity.</li>
      </ul>

      <p className="text-lg mb-6">
        We are committed to continuously improving the dApp and introducing new features to provide a holistic blockchain experience for all our users.
      </p>

      
    </div>
    </div>
  );
};

export default About;

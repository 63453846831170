import React from 'react';
import { useNavigate } from 'react-router-dom';

const CreateWalletDoc = () => {
    const navigate = useNavigate();
    return (
        <div className='bg-gradient-to-b from-gray-800 to-blue-950'>

            <div className="max-w-3xl mx-auto p-6 rounded-lg shadow-lg text-white">
                <h1 className="text-3xl font-bold mb-4">Create Your Ethereum Wallet</h1>

                <p className="text-lg mb-6">
                    Creating an Ethereum wallet is the first step to securely manage your digital assets.
                    A wallet is generated using a unique 12-word recovery phrase (also known as a seed phrase).
                    This phrase is critical for accessing and recovering your wallet in the future.
                </p>

                <h3 className="text-xl font-semibold mb-4">Why is the 12-Phrase Key Important?</h3>
                <ul className="list-disc list-inside mb-6">
                    <li>Your 12-word phrase is your wallet’s master key.</li>
                    <li>
                        This phrase should <strong>only be accessible by you</strong>. If anyone else gets access to it, they can control your wallet and the assets inside it.
                    </li>
                    <li>Never share your recovery phrase with anyone.</li>
                    <li>Store it in a safe place, preferably offline, like on paper or a secure hardware device.</li>
                    <li>If you lose your phrase, you lose access to your wallet permanently.</li>
                </ul>

                <p className="text-red-600 bg-red-100 p-4 rounded-md mb-6">
                    <strong>Note:</strong> Your keys and recovery phrase should never be shared or stored publicly. Anyone with access to your keys can fully control your wallet.
                </p>

                <div className="text-center">
                    <p className="text-lg mb-2">Ready to create your wallet?</p>
                    <p className="font-bold mb-4">
                        You can click the "Sign Up" button to generate your unique 12-word recovery phrase. Please store it securely!
                    </p>
                    <button
                        className="px-6 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 focus:outline-none"
                        onClick={() => navigate('/signup')}>
                        Sign Up
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateWalletDoc;

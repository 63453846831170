import TranHistory from "../Components/TranHistory";
import UseLocalStorage from "../Components/UseLocalStorage";

const Transaction = () => {
    const from = "Transaction";
    const [accounts, setAccounts] = UseLocalStorage("Accounts", "");
    const [currNet, setCurrNet] = UseLocalStorage("Network", "");
    const key = "Transaction";
    return (
        <div className="bg-[#374151] text-white p-[1px]">
            <h1 className="text-[25px] font-bold text-center border-b-2 border-gray-500 p-4">Transaction History</h1>
            {/* accounts.accoutList[accounts.current-1].address */}
            <TranHistory key={key} from={from} address={accounts.accountList[accounts.current-1].address} currNet={currNet} />
        </div>
    );
}

export default Transaction;
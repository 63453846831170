import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoadWalletDoc = () => {
    const navigate = useNavigate();
  return (
    <div className='bg-gradient-to-b from-gray-800 to-blue-950'>
    <div className="max-w-3xl mx-auto p-6 text-white rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold mb-4">Load Your Ethereum Wallet</h1>
      
      <p className="text-lg mb-6">
        To load your Ethereum wallet, you must provide your secret recovery phrase (also known as a seed phrase). 
        This phrase is the key to unlocking your wallet and accessing your digital assets. 
        If you lose or incorrectly enter this phrase, you will not be able to access your wallet.
      </p>

      <h3 className="text-xl font-semibold mb-4">Important Rules to Follow:</h3>
      <ul className="list-disc list-inside mb-6">
        <li>Your secret recovery phrase is case-insensitive, but it must match <strong>exactly</strong> in terms of spelling and order.</li>
        <li>
          If even one word is misspelled or misplaced, your wallet will not load, and you will not be able to access your funds.
        </li>
        <li>The phrase consists of 12 random words. Ensure there are no typos or missing words when you input it.</li>
        <li>Do not shuffle the words or change their order; they must be entered in the correct sequence.</li>
        <li>Be cautious when copying and pasting your recovery phrase. Some hidden characters might prevent it from being recognized correctly.</li>
        <li>Store your phrase securely, as anyone who knows it can gain access to your wallet and the assets within it.</li>
      </ul>

      <p className="text-red-600 bg-red-100 p-4 rounded-md mb-6">
        <strong>Alert:</strong> If your recovery phrase does not match exactly, you will not be able to load your wallet. Double-check every letter, space, and word order before submitting.
      </p>

      <p className="text-red-600 bg-red-100 p-4 rounded-md mb-6">
        <strong>Warning:</strong> Do not share your recovery phrase with anyone. If someone obtains it, they can fully control your wallet, and you will have no recourse.
      </p>

      <div className="text-center">
        <p className="text-lg mb-2">Ready to load your wallet?</p>
        <p className="font-bold mb-4">
          Ensure you have your 12-word recovery phrase ready, and input it exactly as it was generated.
        </p>
        <button 
          className="px-6 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 focus:outline-none"
          onClick={() => navigate('/signin')}>
          Load Wallet
        </button>
      </div>
    </div>
    </div>
  );
};

export default LoadWalletDoc;

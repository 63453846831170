import React from 'react';

const TransferEthDoc = () => {
    return (
        <div className='bg-gradient-to-b from-gray-800 to-blue-950'>
            <div className="max-w-3xl mx-auto p-6 text-white rounded-lg shadow-lg">
                <h1 className="text-3xl font-bold text-gray-800 mb-4">Transfer Ethereum or Tokens</h1>

                <p className="text-lg mb-6">
                    To transfer Ethereum or any other tokens from your wallet, you need to ensure you have all the required information.
                    A successful transaction depends on correctly entering the recipient address, choosing the right amount, and paying sufficient gas fees.
                </p>

                <h3 className="text-xl font-semibold mb-4">Requirements for Transferring Coins:</h3>
                <ul className="list-disc list-inside mb-6">
                    <li><strong>Recipient Address:</strong> Ensure the recipient's Ethereum wallet address is correct. Any mistake in the address will result in the permanent loss of your funds.</li>
                    <li><strong>Amount to Transfer:</strong> Choose the amount of Ethereum or tokens you want to transfer. Make sure you have enough balance in your wallet.</li>
                    <li><strong>Gas Fees:</strong> Every transaction on the Ethereum network requires gas fees. Ensure you have sufficient Ethereum in your wallet to cover these fees. The fees vary depending on network congestion.</li>
                    <li><strong>Token Type:</strong> If you're transferring tokens, ensure you select the correct token type (e.g., ERC-20) and confirm that both you and the recipient support the token in their wallets.</li>
                </ul>

                <p className="text-red-600 bg-red-100 p-4 rounded-md mb-6">
                    <strong>Alert:</strong> Double-check the recipient address before submitting the transfer. Transactions on the blockchain are irreversible, and if sent to the wrong address, you will lose your assets permanently.
                </p>

                <h3 className="text-xl font-semibold mb-4">Things to Consider:</h3>
                <ul className="list-disc list-inside mb-6">
                    <li><strong>Gas Fees Fluctuation:</strong> Gas fees can fluctuate depending on the network's usage. You can choose to wait for lower fees or expedite your transaction by paying higher fees.</li>
                    <li><strong>Transaction Time:</strong> Ethereum transactions might take some time to complete, especially if the gas fee is low. If the transaction remains pending, you can speed it up by adding more gas.</li>
                    <li><strong>Security:</strong> Ensure you're transferring to a trusted recipient and avoid sending tokens or Ethereum to unknown addresses.</li>
                    <li><strong>Token Support:</strong> Some tokens might not be supported on all wallets or exchanges, so double-check compatibility.</li>
                </ul>
            </div>
        </div>
    );
};

export default TransferEthDoc;


const MarketCap = () => {
    return (
        <div>
            <h1>Market Cap</h1>
            {/* Add your market cap data */}
        </div>
    );
}

export default MarketCap;
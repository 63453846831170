
const Networks = {
    MAINNET: 'mainnet',
    LINEA : 'linea',
    LINEA_SEPOLIA: 'linea-sepolia',
    GOERLI: 'goerli',
    POLYGON: 'polygon-mainnet',
    SEPOLIA: 'sepolia',
};


export default Networks;